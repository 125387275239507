
import {computed, defineComponent, ref} from "vue";
import {useActionLogStore} from "@/store/actionlog.store";
import {PinaFilterObjects} from "@/core/composite/composite";
import ActionLogCards from "@/views/entity/ActionLogCards.vue";

export default defineComponent({
  name: "ActionLogs",
  components: {ActionLogCards},
  props: {
    userId: {}
  },
  setup(props) {
    const objectFilter = ref({isCompany: true, userId: props.userId})
    const actionLogStore = useActionLogStore();
    const page = computed(() => actionLogStore.page)
    return {
      page,
      ...PinaFilterObjects(actionLogStore, objectFilter.value, ['user'])
    }
  }
})
