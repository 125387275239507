import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionLogCards = _resolveComponent("ActionLogCards")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ActionLogCards, {
        total: _ctx.page?.logs?.total,
        "card-data": _ctx.page?.logs?.data,
        "current-page": _ctx.filter.paginationPage,
        "rows-per-page": _ctx.filter.paginationSize,
        onCurrentChange: _ctx.handleUpdatePage
      }, null, 8, ["total", "card-data", "current-page", "rows-per-page", "onCurrentChange"])
    ])
  ]))
}